import { useTranslate, useLanguage } from '/machinery/I18n'
import { routeMap } from '/routeMap'
import { HeadingSlantedLarge } from '/features/buildingBlocks/Heading'
import { ButtonLinkBlue, ButtonLinkWhite } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'

import lineTop from '/images/lines/line-thank-you-top.raw.svg'
import lineBottom from '/images/lines/line-thank-you-bottom.raw.svg'

import styles from './Hero.css'

export function Hero({ description, title }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.component}>
      <div className={styles.shapeTop}>
        <Icon icon={lineTop} />
      </div>
      <div className={styles.shapeBottom}>
        <Icon icon={lineBottom} />
      </div>
      <HeadingSlantedLarge
        layoutClassName={styles.title}
        {...{ title }}
        h={1}
      />
      <div className={styles.content}>
        <p className={styles.description}>
          {description}
        </p>
        <div className={styles.buttons}>
          <ButtonLinkBlue
            href={routeMap.app.home({ language })}
            dataX='scroll-to-form'
            layoutClassName={styles.button}
          >
            {__`back-to-home`}
          </ButtonLinkBlue>
          <ButtonLinkWhite
            href='/about'
            dataX='link-to-about'
            layoutClassName={styles.button}
          >
            {__`read-our-story`}
          </ButtonLinkWhite>
        </div>
      </div>
    </div>
  )
}
