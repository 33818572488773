import { useElementSize } from '@kaliber/use-element-size'

import styles from './Heading.css'

const SLANT_RATIO = 3 / 5

export function HeadingMd({ h, title, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentMd, layoutClassName)} {...{ h, title }} />
  )
}

export function HeadingLg({ h, title, layoutClassName = undefined }) {
  return (
    <HeadingBase className={cx(styles.componentLg, layoutClassName)} {...{ h, title }} />
  )
}

export function HeadingSlantedMedium({ h, title, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSlantedMedium, layoutClassName)}>
      <HeadingSlantedBase className={styles.slantedTitleMedium} {...{ h, title }} />
    </div>
  )
}

export function HeadingSlantedLarge({ h, title, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSlantedLarge, layoutClassName)}>
      <HeadingSlantedBase className={styles.slantedTitleLarge} {...{ h, title }} />
    </div>
  )
}

export function HeadingSlantedMediumRight({ h, title, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSlantedMediumRight, layoutClassName)}>
      <HeadingSlantedBase className={styles.slantedTitleMedium} alignRight {...{ h, title }} />
    </div>
  )
}

export function HeadingSlantedExtraLargeRight({ h, title, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSlantedExtraLargeRight, layoutClassName)}>
      <HeadingSlantedBase className={styles.slantedTitleExtraLarge} alignRight {...{ h, title }} />
    </div>
  )
}

function HeadingBase({ title, h, className }) {
  /** @type {any} */
  const HBase = `h${h}`

  return (
    <HBase className={cx(styles.componentBase, className)}>{title}</HBase>
  )
}

function HeadingSlantedBase({ title, h, className, alignRight = undefined }) {
  const { ref: elementRef, size } = useElementSize()
  const height = Math.min(size.height, size.width)

  const style = alignRight
    ? {
      shapeOutside: 'polygon(120% 0, 0 100%, 100% 100%)',
      height: height + 'px',
      width: height * SLANT_RATIO + 'px',
    }
    : {
      shapeOutside: 'polygon(100% 0, 0 0, 0 100%)',
      height: `calc(${height}px - 1em)`,
      width: `calc((${height}px - 1em) * ${SLANT_RATIO})`,
    }

  return (
    <div className={styles.componentSlantedBase} ref={elementRef}>
      <div
        className={cx(styles.shapeOutside, alignRight && styles.alignRight)}
        {...{ style }}
      />
      <HeadingBase title={title + (alignRight ? ' ' : '')} {...{ h, className }} />
    </div>
  )
}
